export const ROUTES = {
  MAIN: "/",
  HOME: "/home",
  TEST: "/test",
  ABOUT_US: "/about-us",
  BLOG: "/blog",
  BLOG_LOAN_FOR_STUDYING: "/blog/loan-for-studying",
  BLOG_LOAN_LEARN_LANGUAGE: "/blog/loan-learn-language",
  BLOG_CAREER_GROWTH_IN_IT: "/blog/career-growth-in-it",
  BLOG_FIVE_LIFEHACKS_FROM_CREDIT_EXPERT:
    "/blog/five-life-hacks-from-credit-expert",
  BLOG_RECEIVE_IN_UKRAINE: "/blog/receive-247-in-ukraine",
  BLOG_HOW_TO_APPLY_FOR_AUTOMATIC_LOAN_FROM_MFO:
    "/blog/how-apply-for-automatic-loan-from-mfo",
  BLOG_PAY_OFF_LOANS_USING_SNOWBALL_METHOD:
    "/blog/pay-of-loans-using-snowball-method",
  STUDENT_LOAN: "/student-loan",
  FAMILY_LOAN: "/family-loan",
  PROMOACTIONNEWS: "/promoactionnews",
  FAQ: {
    FAQ: "/uk/pitannya-ta-vidpovidi",
    COMMON: "/uk/pitannya-ta-vidpovidi/zahalni",
    PAYDAY_LOAN: "/uk/pitannya-ta-vidpovidi/kredyt-do-zarplaty",
    STUDY_LOAN: "/uk/pitannya-ta-vidpovidi/kredyt-na-navchannia",
    FAMILY_LOAN: "/uk/pitannya-ta-vidpovidi/simeinyi-kredyt",
    REPAYMENT_AND_OVERDUE:
      "/uk/pitannya-ta-vidpovidi/pohashennia-kredytu-y-prostrochennia",
    REJECT: "/uk/pitannya-ta-vidpovidi/vidmova",
    ACCOUNT: "/uk/pitannya-ta-vidpovidi/mii-kabinet",
    PROLONGATION: "/uk/pitannya-ta-vidpovidi/prolonhatsiia-kredytu",
    GUARANTOR: "/uk/pitannya-ta-vidpovidi/poruchytel",
    PROMOCODES: "/uk/pitannya-ta-vidpovidi/promokody",
    LEGAL: "/uk/pitannya-ta-vidpovidi/yurydychni-pytannia",
  },
  LOYALTY_PROGRAM: "/loyalty-program",
  REVIEWS: "/reviews",
  PROLONGATION_GUIDE: "/prolongation-guide",
  PROMOACTIONNEWS_EASTER: "/promoactionnews/easter-2025",
};
