import React, { lazy } from "react";
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  ScrollRestoration,
  useLocation,
} from "react-router-dom";

import { ROUTES } from "./routes";
import Chat from "modules/Chat/Chat";

const FaqCommon = lazy(
  () => import("../../pages/FaqPages/FaqCommon/FaqCommon")
);
const FaqPaydayLoan = lazy(
  () => import("../../pages/FaqPages/FaqPaydayLoan/FaqPaydayLoan")
);
const FaqStudyLoan = lazy(
  () => import("../../pages/FaqPages/FaqStudyLoan/FaqStudyLoan")
);
const FaqFamilyLoan = lazy(
  () => import("../../pages/FaqPages/FaqFamilyLoan/FaqFamilyLoan")
);
const FaqRepaymentAndOverdue = lazy(
  () =>
    import("../../pages/FaqPages/FaqRepaymentAndOverdue/FaqRepaymentAndOverdue")
);
const FaqReject = lazy(
  () => import("../../pages/FaqPages/FaqReject/FaqReject")
);
const FaqAccount = lazy(
  () => import("../../pages/FaqPages/FaqAccount/FaqAccount")
);
const FaqProlongation = lazy(
  () => import("../../pages/FaqPages/FaqProlongation/FaqProlongation")
);
const FaqGuarantor = lazy(
  () => import("../../pages/FaqPages/FaqGuarantor/FaqGuarantor")
);
const FaqPromocodes = lazy(
  () => import("../../pages/FaqPages/FaqPromocodes/FaqPromocodes")
);
const FaqLegal = lazy(() => import("../../pages/FaqPages/FaqLegal/FaqLegal"));
const PaydayLoan = lazy(() => import("../../pages/PaydayLoan/PaydayLoan"));
const Home = lazy(() => import("../../pages/Home/Home"));
const Test = lazy(() => import("../../pages/Test/Test"));
const AboutUs = lazy(() => import("../../pages/AboutUs/AboutUs"));
const Blog = lazy(() => import("../../pages/Blog/Blog"));
const HowToGetLoanForStudying = lazy(
  () => import("../../pages/BlogPages/HowToGetLoanForStudying")
);
const HowToGetLoanLearnLanguage = lazy(
  () => import("../../pages/BlogPages/HowToGetLoanLearnLanguage")
);
const CareerGrowthInIt = lazy(
  () => import("../../pages/BlogPages/CareerGrowthInIt")
);
const BlogFiveLifeHacksFromCreditExpert = lazy(
  () => import("../../pages/BlogPages/BlogFiveLifeHacksFromCreditExpert")
);
const ReceiveInUkraine = lazy(
  () => import("../../pages/BlogPages/ReceiveInUkraine/ReceiveInUkraine")
);
const HowApplyForAutomaticLoanFromMfo = lazy(
  () => import("../../pages/BlogPages/HowApplyForAutomaticLoanFromMfo")
);
const StartCareerInItToday = lazy(
  () => import("../../pages/BlogPages/StartCareerInItToday")
);
const PayOffLoansUsingSnowballMethod = lazy(
  () =>
    import(
      "../../pages/BlogPages/PayOffLoansUsingSnowballMethod/PayOffLoansUsingSnowballMethod"
    )
);
const StudyLoan = lazy(() => import("../../pages/StudyLoan"));
const FamilyLoan = lazy(() => import("../../pages/FamilyLoan/FamilyLoan"));
const Stock = lazy(() => import("../../pages/Stock/Stock"));
const FaqPage = lazy(() => import("../../pages/Faq/Faq"));
const LoyaltyProgram = lazy(
  () => import("../../pages/LoyaltyProgram/LoyaltyProgram")
);
const NeedMoney = lazy(() => import("../../pages/StockPages/NeedMoney"));
const MoneyBeforeSalary = lazy(
  () => import("../../pages/StockPages/MoneyBeforeSalary")
);
const MoneyAutumn = lazy(() => import("../../pages/StockPages/MoneyAutumn"));
const SimpleSteps = lazy(() => import("../../pages/StockPages/SimpleSteps"));
const RomanceCost = lazy(() => import("../../pages/StockPages/RomanceCost"));
const DocumentsAreRequired = lazy(
  () => import("../../pages/StockPages/DocumentsAreRequired")
);
const MoneyAutumnContinues = lazy(
  () => import("../../pages/StockPages/MoneyAutumnContinues")
);
const TeacherSchool = lazy(
  () => import("../../pages/StockPages/TeacherSchool")
);
const InterestRate = lazy(() => import("../../pages/StockPages/InterestRate"));
const BlackFriday = lazy(() => import("../../pages/StockPages/BlackFriday"));
const ReviewsPage = lazy(() => import("../../pages/Reviews/Reviews"));
const HappyHolidayDiscount2024 = lazy(
  () => import("../../pages/StockPages/HappyHolidayDiscount2024")
);
const ValentinesDay2024 = lazy(
  () => import("../../pages/StockPages/ValentinesDay2024")
);
const AssessFinancialCapabilities = lazy(
  () =>
    import(
      "../../pages/BlogPages/AssessFinancialCapabilities/AssessFinancialCapabilities"
    )
);
const ProlongationGuide = lazy(
  () => import("../../pages/ProlongationGuide/ProlongationGuide")
);
const WinIphone = lazy(() => import("../../pages/StockPages/WinIphone"));
const Easter2025 = lazy(() => import("../../pages/StockPages/Easter2025"));

const AppLayout = () => {
  const location = useLocation();

  return (
    <>
      <ScrollRestoration />
      <Outlet />
      {location.pathname !== ROUTES.TEST && <Chat />}
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    children: [
      {
        path: ROUTES.MAIN,
        element: <PaydayLoan />,
      },
      {
        path: ROUTES.HOME,
        element: <Home />,
      },
      {
        // For testing purposes only
        path: ROUTES.TEST,
        element: <Test />,
      },
      {
        path: ROUTES.ABOUT_US,
        element: <AboutUs />,
      },
      {
        path: ROUTES.BLOG,
        element: <Blog />,
      },
      {
        path: ROUTES.BLOG_LOAN_FOR_STUDYING,
        element: <HowToGetLoanForStudying />,
      },
      {
        path: ROUTES.BLOG_LOAN_LEARN_LANGUAGE,
        element: <HowToGetLoanLearnLanguage />,
      },
      {
        path: ROUTES.BLOG_CAREER_GROWTH_IN_IT,
        element: <CareerGrowthInIt />,
      },
      {
        path: ROUTES.BLOG_FIVE_LIFEHACKS_FROM_CREDIT_EXPERT,
        element: <BlogFiveLifeHacksFromCreditExpert />,
      },
      {
        path: ROUTES.BLOG_RECEIVE_IN_UKRAINE,
        element: <ReceiveInUkraine />,
      },
      {
        path: ROUTES.BLOG_HOW_TO_APPLY_FOR_AUTOMATIC_LOAN_FROM_MFO,
        element: <HowApplyForAutomaticLoanFromMfo />,
      },
      {
        path: ROUTES.BLOG_PAY_OFF_LOANS_USING_SNOWBALL_METHOD,
        element: <PayOffLoansUsingSnowballMethod />,
      },
      {
        path: "/blog/start-career-in-it-today",
        element: <StartCareerInItToday />,
      },
      {
        path: ROUTES.STUDENT_LOAN,
        element: <StudyLoan />,
      },
      {
        path: ROUTES.FAMILY_LOAN,
        element: <FamilyLoan />,
      },
      {
        path: ROUTES.PROMOACTIONNEWS,
        element: <Stock />,
      },
      {
        path: "/promoactionnews/need-money",
        element: <NeedMoney />,
      },
      {
        path: "/promoactionnews/money-before-salary",
        element: <MoneyBeforeSalary />,
      },
      {
        path: "/promoactionnews/money-autumn",
        element: <MoneyAutumn />,
      },
      {
        path: "/promoactionnews/4-simple-steps",
        element: <SimpleSteps />,
      },
      {
        path: "/promoactionnews/romance-cost",
        element: <RomanceCost />,
      },
      {
        path: "/promoactionnews/documents-are-required",
        element: <DocumentsAreRequired />,
      },
      {
        path: "/promoactionnews/money-autumn-continues",
        element: <MoneyAutumnContinues />,
      },
      {
        path: "/promoactionnews/teacher-school",
        element: <TeacherSchool />,
      },
      {
        path: "/promoactionnews/interest-rate",
        element: <InterestRate />,
      },
      {
        path: "/promoactionnews/black-friday",
        element: <BlackFriday />,
      },
      {
        path: "/promoactionnews/happy-holiday-24-12-2024",
        element: <HappyHolidayDiscount2024 />,
      },
      {
        path: "/promoactionnews/valentines-day-2025",
        element: <ValentinesDay2024 />,
      },
      {
        path: ROUTES.PROMOACTIONNEWS_EASTER,
        element: <Easter2025 />,
      },
      {
        path: ROUTES.FAQ.FAQ,
        element: <FaqPage />,
      },
      {
        path: ROUTES.FAQ.COMMON,
        element: <FaqCommon />,
      },
      {
        path: ROUTES.FAQ.PAYDAY_LOAN,
        element: <FaqPaydayLoan />,
      },
      {
        path: ROUTES.FAQ.STUDY_LOAN,
        element: <FaqStudyLoan />,
      },
      {
        path: ROUTES.FAQ.FAMILY_LOAN,
        element: <FaqFamilyLoan />,
      },
      {
        path: ROUTES.FAQ.REPAYMENT_AND_OVERDUE,
        element: <FaqRepaymentAndOverdue />,
      },
      {
        path: ROUTES.FAQ.REJECT,
        element: <FaqReject />,
      },
      {
        path: ROUTES.FAQ.ACCOUNT,
        element: <FaqAccount />,
      },
      {
        path: ROUTES.FAQ.PROLONGATION,
        element: <FaqProlongation />,
      },
      {
        path: ROUTES.FAQ.GUARANTOR,
        element: <FaqGuarantor />,
      },
      {
        path: ROUTES.FAQ.PROMOCODES,
        element: <FaqPromocodes />,
      },
      {
        path: ROUTES.FAQ.LEGAL,
        element: <FaqLegal />,
      },
      {
        path: "/promoactionnews/win-iphone",
        element: <WinIphone />,
      },
      {
        path: ROUTES.LOYALTY_PROGRAM,
        element: <LoyaltyProgram />,
      },
      {
        path: ROUTES.REVIEWS,
        element: <ReviewsPage />,
      },
      {
        path: "/blog/assess-financial-capabilities",
        element: <AssessFinancialCapabilities />,
      },
      {
        path: ROUTES.PROLONGATION_GUIDE,
        element: <ProlongationGuide />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

export default router;
